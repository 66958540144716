import { useState } from "react";

const useFetchOrgCustomLogo = (orgId) => {
  const [logo, setLogo] = useState();

  const fetchOrgCustomLogo = async () => {
    if (orgId) {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({ orgId: orgId }),
      };
      try {
        const res = await fetch(
          `${process.env.REACT_APP_HOST_URL}/download_org_logo`,
          requestOptions,
        );
        if (!res.ok) {
          throw new Error("error fetching org custom logo");
        }
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setLogo({ imageObjectURL });
      } catch (error) {
        setLogo({ error });
      }
    }
  };

  return {
    fetchOrgCustomLogo,
    imageObjectURL: logo?.imageObjectURL,
    error: logo?.error,
  };
};

export default useFetchOrgCustomLogo;
