import Proptypes from "prop-types";
import React from "react";

import classNames from "@lango/common/classnames";
import { Loader } from "@lango/common/components";

/**
 * Avatar component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.placeholderInitials - The initials to display when no image is available.
 * @param {string} props.extraClasses - Additional CSS classes for the avatar container.
 * @param {string} [props.iconClasses] - Additional CSS classes for the placeholder initials.
 * @param {string} [props.imageUrl] - The URL of the image to display as the avatar.
 * @param {boolean} [props.editMode] - Indicates whether the avatar is in edit mode.
 * @returns {JSX.Element} The Avatar component.
 */
const Avatar = ({
  placeholderInitials,
  extraClasses,
  iconClasses = "",
  imageUrl,
  editMode = false,
  loading = false,
}) => {
  const avatarClasses = classNames(
    "relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600",
    extraClasses
  );

  const hoverOpacity = `transition-opacity duration-300 ${(editMode || loading) ? "opacity-30" : "opacity-100"}`;
  const iconClassNames = classNames(
    "font-medium text-gray-600 dark:text-gray-300",
    iconClasses
  );

  return (
    <>
      {imageUrl ? (
        <>
          <img
            className={`${avatarClasses} ${hoverOpacity}`}
            src={imageUrl}
            alt="avatar"
          />
          {renderOverlays(editMode, loading)}
        </>
      ) : (
        <div className={avatarClasses}>
          <span className={`${iconClassNames} ${hoverOpacity}`}>
            {placeholderInitials}
          </span>
          {renderOverlays(editMode, loading)}
        </div>
      )}
    </>
  );
};

/**
 * Render the overlays for the avatar
 * @param editMode
 * @param loading
 * @returns
 */
const renderOverlays = (editMode, loading) => {
  if (editMode && !loading) return renderEditOverlay();
  if (loading) return renderLoaderOverlay();
}

/**
 * Render the edit overlay
 * @returns {React.JSX.Element}
 */
const renderEditOverlay = () => (
  <div className="absolute inset-0 flex items-center justify-center rounded-full text-black font-medium text-3xl">
    Edit
  </div>
);

const renderLoaderOverlay = () => (
  <div className="absolute inset-0 flex items-center justify-center rounded-full text-black text-xl">
    <Loader message="Uploading..." extraClasses={"text-black"} extraSpinnerClasses={"text-black"}/>
  </div>
);

Avatar.propsTypes = {
  placeholderInitials: Proptypes.string,
  extraClasses: Proptypes.string,
  iconClasses: Proptypes.string,
  imageUrl: Proptypes.string,
  isHovered: Proptypes.bool,
};

export default Avatar;
