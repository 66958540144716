import { Form, useFormikContext } from "formik";

import { Flex, FormControl } from "@lango/common/features";
import { JobTypeLanguagePair } from "@lango/common/features/forms/fields";

const LanguagePairFormFields = () => {
  const { isSubmitting, dirty } = useFormikContext();

  return (
    <Form className="space-y-8">
      <JobTypeLanguagePair crowd={false} enableGeneralRouting={false} />

      <Flex justify="center" extraClasses="pt-8">
        <FormControl
          control="button"
          type="submit"
          text="Submit"
          disabled={isSubmitting || !dirty}
          showLoader={isSubmitting}
        />
      </Flex>
    </Form>
  );
};

export default LanguagePairFormFields;
