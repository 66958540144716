import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useCallback } from "react";

import { useVendorProfile } from "@lango/common/context";
import { GET_PAGINATED_VENDOR_INVITATIONS } from "@lango/common/queries";
import { paginatedVendorInvitationRequest } from "@lango/common/request";

/**
 * useGetPaginatedVendorInvitations Hook
 *
 * This is a custom hook that fetches paginated vendor invitations.
 * It uses a lazy query from Apollo Client to fetch the data when needed.
 *
 * @hook useGetPaginatedVendorInvitations
 * @return object containing vendor invitations data, total records, function to fetch data, loading, and error.
 */
export const useGetPaginatedVendorInvitations = ({excludeAccepted = false}) => {
  const { vendorProfile } = useVendorProfile();
  const [paginatedVendorInvitations, { data, loading, error }] = useLazyQuery(
    GET_PAGINATED_VENDOR_INVITATIONS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
    },
  );

  const fetchVendorInvitations = useCallback(
    (props) => {
      paginatedVendorInvitations(
        paginatedVendorInvitationRequest({
          ...props,
          vendorProfileID: vendorProfile?.id,
          excludeAccepted,
        }),
      );
    },
    [paginatedVendorInvitations],
  );

  return {
    data: data?.paginatedVendorInvitations?.vendorInvitations || [],
    totalRecords: data?.paginatedVendorInvitations?.totalRecords,
    fetchData: fetchVendorInvitations,
    loading,
    error,
  };
};
