import { Popover, PopoverButton } from "@headlessui/react";
import { MdNotificationsNone } from "react-icons/md";

import {
  useGetPaginatedOrgNotifications,
  useReadAllOrgNotifications,
  useReadOrgNotification,
} from "@lango/common/hooks";
import cn from "@lango/common/classnames";
import { useNavigate } from "react-router-dom";
import { PM_APP, VENDOR_APP } from "@lango/common/constants";
import environmentVars from "@lango/common/env";

import { NotificationPanel } from "./panel";

const appName = environmentVars.appName;

/**
 * OrganizationNotifications Component
 *
 * Renders a notification bell icon that indicates the presence of unread notifications.
 * When clicked, it displays the NotificationPanel component that lists all notifications.
 * The component also shows a red dot on the bell icon if there are any notifications.
 *
 * @param {Object} props - The props for the OrganizationNotifications component.
 * @param {string} [props.extraClasses] - Additional classes to apply to the component.
 * @returns {React.ReactNode} The rendered OrganizationNotifications component.
 */
const OrganizationNotifications = ({ extraClasses }) => {
  const { handleReadNotification } = useReadOrgNotification();
  const { handleReadAllNotifications } = useReadAllOrgNotifications();
  const { data, unreadCounts } = useGetPaginatedOrgNotifications();
  const navigate = useNavigate();

  const handleViewAll = () => {
    if (appName === VENDOR_APP) {
      navigate("/notifications");
    } else if (appName === PM_APP) {
      navigate("/notifications/organization");
    }
  };

  return (
    <Popover className={cn(`relative ${extraClasses}`)}>
      <PopoverButton className="items-center rounded-full px-1 py-1 !border-gray-200 border bg-white">
        <MdNotificationsNone size="28px" />
        {unreadCounts > 0 && (
          <span className="absolute top-0 left-6 block h-3 w-3 rounded-full bg-red-500 ring-2 ring-white" />
        )}
      </PopoverButton>
      <NotificationPanel
        notifications={data}
        handleAllRead={handleReadAllNotifications}
        handleClick={handleReadNotification}
        handleViewAll={handleViewAll}
      />
    </Popover>
  );
};

export default OrganizationNotifications;
