import { useField } from "formik";
import React from "react";

import classNames from "@lango/common/classnames";
import InputLabel from "@lango/common/features/forms/components/InputLabel";

import { FieldIcon } from "./FieldIcon";

const RateInput = ({
  label,
  showLabel,
  extraClasses,
  size,
  infoText,
  currencyOpts,
  icon,
  type,
  ...props
}) => {
  const [field, meta] = useField(props);

  function renderOptionsAsName(options) {
    const opts = options.map((option) => (
      <option key={option.id} value={option.name}>
        {option.name}
      </option>
    ));
    return <>{opts}</>;
  }

  let btnClass = classNames(
    "block w-full pl-7 border border-gray-200 text-black focus:ring-gray-700 focus:border-gray-700 placeholder-gray-400",
    extraClasses,
    {
      "py-2 px-8 rounded": size === "small",
      "py-3 px-8 bg-gray-100 rounded-full": size !== "small",
    },
    {
      "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
        meta.touched && meta.error,
    },
  );

  return (
    <div className={"text-left"}>
      {showLabel !== false ? (
        <InputLabel
          labelFor={props.id || props.name}
          text={label}
          infoText={infoText}
        />
      ) : null}

      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-2 flex items-center pl-3">
          <span>$</span>
        </div>
        <input
          type={type}
          className={btnClass}
          aria-describedby={props.errorMsgID}
          {...field}
          {...props}
        />

        {currencyOpts && currencyOpts.length > 0 ? (
          <div className="absolute inset-y-0 right-0 flex items-center">
            <label htmlFor="currency" className="sr-only">
              Currency
            </label>
            <select
              id="currency"
              name="currency"
              className="h-full rounded-full border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-black focus:ring-black sm:text-sm"
              defaultValue={props.defaultcurrency}
            >
              {renderOptionsAsName(currencyOpts)}
            </select>
          </div>
        ) : null}
        <FieldIcon icon={icon} meta={meta} />
      </div>
      {meta.touched && meta.error ? (
        <p className="ml-3 mt-2 h-1 text-sm text-red-600" id={props.errorMsgID}>
          {meta.error}
        </p>
      ) : null}
    </div>
  );
};

RateInput.defaultProps = {
  infoText: null,
  defaultcurrency: "USD",
  type: "text",
};

export default RateInput;
