import { useMutation } from "@apollo/client";
import { useCallback } from "react";

import { hasPictureVar } from "@lango/common/apollo";
import { useUserPictureContext } from "@lango/common/context";
import { toastError, toastSuccess, toastWarning } from "@lango/common/features";
import { UPLOAD_USER_PICTURE } from "@lango/common/queries";
import environmentVars from "@lango/common/env";
import { convertBytes } from "@lango/common/helpers";

export const useUploadUserPicture = (setIsHovered) => {
  const [uploadUserPicture, {loading}] = useMutation(UPLOAD_USER_PICTURE);
  const { setUserPicture } = useUserPictureContext();

  const validateFile = (file) => {
    if (!file) {
      toastWarning("No file has been selected");
      return false;
    }

    switch(file.type) {
      case "image/jpeg":
      case "image/png":
        break;
      default:
        toastWarning("Only JPEG and PNG files are allowed");
        return false;
    }

    if (file.size > environmentVars.avatarFileSizeLimit) {
      toastWarning("File size cannot be larger than " + convertBytes(environmentVars.avatarFileSizeLimit));
      return false;
    }

    return true;
  }

  const handleUploadPicture = useCallback(
    async (event) => {
      event.preventDefault();
      const file = event?.target?.files[0];

      if (!validateFile(file)) return;

      try {
        await uploadUserPicture({ variables: { file: file } });
        setUserPicture(URL.createObjectURL(file));
        setIsHovered(false);
        hasPictureVar(true);
        toastSuccess("Picture uploaded successfully");
      } catch (error) {
        console.error(error);
        toastError("There was an error uploading the picture");
      }
    },
    [uploadUserPicture, setIsHovered, setUserPicture],
  );

  return { handleUploadPicture, loading };
};
