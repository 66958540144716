/**
 * Render Helpers
 */
import Moment from "react-moment";
import "moment-timezone";

import environmentVars from "@lango/common/env";
import Status from "@lango/common/components/Status";
import Languages from "@lango/common/components/Languages";

import { preferredTimezoneVar } from "../apollo/localState";
import TimeAgo from "../components/TimeAgo";
import {
  COMPOSITION_VIDEO_STATUSES,
  ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE,
} from "../constants";
import { CompositionVideoSekeleton } from "../components/Skeletons";
import { CompositionAudioSekeleton } from "../components/Skeletons/CompositionAudio";
import VideoPlayer from "../components/RecordingPlayback/VideoPlayer";
import AudioPlayer from "../components/RecordingPlayback/AudioPlayer";
import ErrorBanner from "../features/alerts/components/ErrorBanner";

export const renderRate = (rate) => (rate ? `$${rate.toFixed(2)}` : "$0.00");

export const renderJobStatus = (value) => value && <Status type={value} />;

export const renderJobLanguagePair = (value) =>
  value && (
    <Languages
      languageFrom={value.languageFrom}
      languageTo={value.languageTo}
      sourceLanguages={value.sourceLanguages}
    />
  );

export const renderDate = (value) => {
  return value ? (
    <Moment format={environmentVars.dateFormat} tz={preferredTimezoneVar()}>
      {value}
    </Moment>
  ) : null;
};

export const renderTime = (value) => {
  return value ? (
    <Moment format={environmentVars.timeFormat} tz={preferredTimezoneVar()}>
      {value}
    </Moment>
  ) : null;
};

export const renderDateAndTime = (value) => {
  return value && <TimeAgo date={value} />;
};

export const renderDuration = (startTime, endTime) => {
  const startTimeObj = new Date(startTime);
  const endTimeObj = new Date(endTime);

  const duration = (endTimeObj - startTimeObj) / 1000;

  const date = new Date(null);
  date.setSeconds(duration);

  const regex = /T(\d{2}:\d{2}:\d{2})/;
  const match = date.toISOString().match(regex);

  return match[1];
};

// assumes value is an object with firstName and lastName properties
export const renderFullName = (value) => {
  if (!value) return "";
  return `${value.firstName} ${value.lastName}`;
};

// render recording media
// if type is "On-Demand Video Interpretation" then it will render video player else it will render audio player
export const renderMediaComponent = ({
  compositionStatus,
  jobType,
  getRecordingSignedUrl,
  signedUrl,
}) => {
  const { CREATED, IN_PROGRESS, COMPLETED, FAILED } =
    COMPOSITION_VIDEO_STATUSES;
  switch (compositionStatus) {
    case CREATED:
    case IN_PROGRESS:
      return jobType === ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE ? (
        <CompositionVideoSekeleton />
      ) : (
        <CompositionAudioSekeleton />
      );
    case COMPLETED:
      return jobType === ON_DEMAND_VIDEO_INTERPRETATION_JOB_TYPE ? (
        <VideoPlayer
          getRecordingSignedUrl={getRecordingSignedUrl}
          signedUrl={signedUrl}
        />
      ) : (
        <AudioPlayer
          getRecordingSignedUrl={getRecordingSignedUrl}
          signedUrl={signedUrl}
        />
      );
    case FAILED:
      return (
        <ErrorBanner message="There was an error loading the recording for this request" />
      );
    default:
      break;
  }
};
