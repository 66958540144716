import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useField } from "formik";

import classNames from "@lango/common/classnames";
import InputLabel from "@lango/common/features/forms/components/InputLabel";

const Textarea = ({
  label,
  showLabel,
  extraClasses,
  size,
  infoText = null,
  ...props
}) => {
  const [field, meta] = useField(props);
  let btnClass = classNames(
    "block w-full border border-gray-200 text-black focus:ring-gray-700 focus:border-gray-700 placeholder-gray-400",
    extraClasses,
    {
      "py-2 px-2 rounded": size === "small",
      "py-3 px-5 bg-gray-100 rounded-md": size !== "small",
    },
    {
      "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500":
        meta.touched && meta.error,
    }
  );
  return (
    <div className={"text-left"}>
      {showLabel !== false ? (
        <InputLabel
          labelFor={props.id || props.name}
          text={label}
          infoText={infoText}
        />
      ) : null}
      <div className="relative">
        <textarea
          className={btnClass}
          aria-describedby={props.errorMsgID}
          rows={6}
          {...field}
          {...props}
        ></textarea>
        {meta.touched && meta.error ? (
          <div className="pointer-events-none absolute top-0 right-0 top-1.5 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      <p className="ml-3 mt-2 h-1 text-sm text-red-600" id={props.errorMsgID}>
        {meta.touched && meta.error ? meta.error : null}
      </p>
    </div>
  );
};

export default Textarea;
