import { useGetPaginatedOrgNotifications } from "@lango/common/hooks";
import { WithLoader } from "@lango/common/components";
import Table from "@lango/common/features/tables/components/Table";

import { columns } from "./columns";

export const PaginatedOrgNotifications = () => {
  const { loading, error, ...props } = useGetPaginatedOrgNotifications();
  return (
    <>
      <Table columns={columns} {...props} enablePagination defaultPageSize={10} />
      <WithLoader error={error} loading={loading} />
    </>
  );
};
