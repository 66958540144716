import React, { useEffect } from "react";

import classNames from "@lango/common/classnames";
import LangoLogo from "@lango/common/components/langoLogo";

import useFetchOrgCustomLogo from "./useFetchOrgCustomLogo";

const LoadHeaderCustomLogo = ({ orgId, extraClasses }) => {
  const { imageObjectURL, error, fetchOrgCustomLogo } =
    useFetchOrgCustomLogo(orgId);

  useEffect(() => {
    fetchOrgCustomLogo();
  }, []);

  return error ? (
    <LangoLogo />
  ) : imageObjectURL ? (
    <img
      src={imageObjectURL}
      id={"headerCustomLogo"}
      className={classNames("block h-8", extraClasses)}
      alt={"Logo"}
    />
  ) : null;
};

export default LoadHeaderCustomLogo;
