import { ChevronDownIcon, UserIcon } from "@heroicons/react/solid";
import { useUserPictureContext } from "@lango/common/context";
import React, { Fragment } from "react";

const UserProfile = ({
  userFirstName,
  userLastName,
  activeOrgName,
  unreadNotificationsCount,
}) => {
  const { userPicture } = useUserPictureContext();  
  const displayOrgName = activeOrgName != userFirstName  + " " + userLastName;

  return (
    <Fragment>
      <div className="mx-1.5 h-7 w-7 rounded-full bg-gray-100 md:mx-3 md:h-8 md:w-8">
        {userPicture ? (
          <img
            src={userPicture}
            alt="User Profile"
            className="h-full w-full rounded-full object-cover"
          />
        ) : (
          <UserIcon className="mx-auto mt-0.5 h-5 w-5 md:mt-0 md:h-6 md:w-6" />
        )}
        {unreadNotificationsCount > 0 && (
          <span className="absolute top-1 left-9 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white" />
        )}
      </div>
      <div className="text-sm">
        <span className="hidden sm:inline">
          <strong>
            {userFirstName} {userLastName}
          </strong>
        </span>
        <span className="inline-block align-top">
          <ChevronDownIcon
            className="mr-2 h-5 w-5 align-top group-hover:text-gray-700 sm:ml-2 sm:mr-5"
            aria-hidden="true"
          />
        </span>
        {displayOrgName && (
        <div className="hidden text-left sm:block">
          {activeOrgName ?? (
            <span className={"italic text-gray-500"}>No active company</span>
          )}
        </div>
        )}
      </div>
    </Fragment>
  );
};

export default UserProfile;
