import { authStateVar, usernameVar } from "../apollo";

export const preparePaginatedJobTaskRequest = (props = {}) => {
  const { pageIndex, pageSize, sortBy, jobID, organizationID } = props;
  return {
    variables: {
      organizationID,
      jobID,
      offset: pageIndex * pageSize,
      pageLimit: pageSize,
      ...prepareSortBy(sortBy),
    },
  };
};

const prepareSortBy = (sortBy) => {
  return sortBy?.length > 0
    ? { sortBy: sortBy[0].id, sortDir: sortBy[0].desc ? "DESC" : "ASC" }
    : { sortBy: "task.id", sortDir: "DESC" };
};

export const formatCode = (code) => {
  if (!code) return "";
  const codeStr = code.toString();
  return `${codeStr.slice(0, 3)}-${codeStr.slice(3, 6)}-${codeStr.slice(6)}`;
};

export const prepareLinguistProfile = (data) => {
  const profile = data?.findLinguistProfile;
  const user = profile?.user;
  return {
    name: user ? `${user.firstName} ${user.lastName}` : "",
    linguistProfileID: profile?.id,
    isActive: profile?.isActive,
    linguistUniqueID: formatCode(profile?.linguistUniqueID),
  };
};

/**
 * Checks the authentication status of the user.
 * Returns an object containing the authentication status.
 * Returns a boolean return.isAuthenticatedWithEmailMatch - True if the user is authenticated and the email matches.
 * Returns a boolean return.isAuthenticatedWithoutEmailMatch - True if the user is authenticated but the email does not match.
 */
export const checkAuthenticationStatus = (email) => {
  const currentAuthState = authStateVar();
  const currentUsername = usernameVar();
  const isUserAuthenticated = currentAuthState === "signedIn";

  return {
    isAuthenticatedWithEmailMatch:
      isUserAuthenticated && currentUsername === email,
    isAuthenticatedWithoutEmailMatch:
      isUserAuthenticated && currentUsername !== email,
  };
};

/**
 * Formats a given phone number as a US standard phone number.
 *
 * This function takes a numeric or string input and returns the phone number
 * formatted in the standard US format (e.g. (415) 555-0132). If no input is 
 * provided or the input is empty, it returns an empty string.
 */
export const formatPhoneNumberUS = (number) => {
  if (!number) return "";
  const codeStr = number.toString();
  return `(${codeStr.slice(0, 3)}) ${codeStr.slice(3, 6)}‑${codeStr.slice(6)}`;
};
