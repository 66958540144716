import { Form, Formik } from "formik";

import { WithLoader } from "@lango/common/components";
import {
  HUMAN_TRANSLATION,
  ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY,
  MACHINE_TRANSLATION_JOB_TYPE_CATEGORY,
} from "@lango/common/constants";

import { SettingsTabContainer } from "@lango/common/features";
import Button from "@lango/common/features/forms/components/Button";
import CheckboxList from "@lango/common/features/forms/components/CheckboxList";
import { useFetchJobTypes } from "@lango/common/hooks";

import fetchVendorServiceLines from "./fetchVendorServiceLines";
import fetchClientServiceLines from "./fetchClientServiceLines";
import {
  buildInitialValues,
  buildServiceLines,
} from "@lango/common/features/serviceLines/utils";

/**
 * Renders the ServiceLines component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.vendorProfileID - The vendor profile ID.
 * @param {string} [props.organizationID] - The organization ID.
 * @param {boolean} props.canUpdateOrg - Indicates if the organization can be updated.
 * @param {boolean} props.onBoardingLinguist - Indicates if onBoarding service lines.
 * @param {any} props.onSubmit - The submit event handler.
 * @param {string} [props.className=""] - The CSS class name for the component.
 * @param {string} [props.buttonText="Save"] - The text for the submit button.
 * @returns {JSX.Element} The rendered ServiceLines component.
 */
export const ServiceLines = ({
  vendorProfileID,
  organizationID,
  canUpdateOrg,
  onBoardingLinguist = false,
  onSubmit,
  className = "",
  buttonText = "Save",
}) => {
  const types = [
    ...(onBoardingLinguist ? [] : [HUMAN_TRANSLATION]),
    ...(organizationID ? [MACHINE_TRANSLATION_JOB_TYPE_CATEGORY] : []),
    ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY,
  ];

  const { loading, error, allJobTypes } = useFetchJobTypes(types);
  const { loading: serviceLinesLoading, values: savedValues } = vendorProfileID
    ? fetchVendorServiceLines(vendorProfileID)
    : fetchClientServiceLines(organizationID);

  // Assemble set of available service lines, merging the DB results with the metadata.
  const serviceLines = buildServiceLines(allJobTypes, organizationID);

  // Initialize form values with the saved values from the DB.
  const initialValues = buildInitialValues(
    allJobTypes,
    serviceLinesLoading,
    savedValues,
  );

  return (
    <WithLoader loading={loading || serviceLinesLoading} error={error}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <SettingsTabContainer className={className}>
            <CheckboxList
              name={"selectedJobTypes"}
              label={"Select Service Lines"}
              items={serviceLines}
            />
          </SettingsTabContainer>

          {canUpdateOrg && (
            <div className="flex justify-end">
              <Button text={buttonText} type="submit" />
            </div>
          )}
        </Form>
      </Formik>
    </WithLoader>
  );
};

export default ServiceLines;
