import { BUYER_APP, PM_APP, VENDOR_APP } from "../constants";

/**
 * This file is used to define environment variables that are used in the UI.
 *
 */
const environmentVars = {
  env: process.env.REACT_APP_ENV,
  appName: process.env.REACT_APP_NAME || "",
  debugLog: process.env.REACT_APP_DEBUG_LOG === "true",
  vriAppUrl: process.env.REACT_APP_VRI_APP_URL,
  twilioSSOUrl: process.env.REACT_APP_TWILIO_SSO_URL,
  // auth0
  auth0ClientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0ResponseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
  auth0Scope: process.env.REACT_APP_AUTH0_SCOPE,
  auth0Realm: process.env.REACT_APP_AUTH0_REALM,
  // date/time formats
  dateFormat: process.env.REACT_APP_DATE_FORMAT ?? "M/D/YYYY",
  timeFormat: process.env.REACT_APP_TIME_FORMAT ?? "h:mm A",
  durationFormat: process.env.REACT_APP_DURATION_FORMAT ?? "H:mm:ss",
  dateTimeFormat: process.env.REACT_APP_DATE_TIME_FORMAT ?? "M/D/YYYY  h:mm A",
  signoutRedirectURL: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT_URL,
  signInRedirectURL: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN_URL,
  sessionKeepaliveMinutes:
    process.env.REACT_APP_SESSION_KEEPALIVE_MINUTES || 10,
  idleTimeoutMinutes: process.env.REACT_APP_IDLE_TIMEOUT_MINUTES || 15,

  // file size limits
  realTimeFileUploadLimit: process.env.REACT_APP_FILE_UPLOAD_LIMIT,
  realTimeFileSizeLimit: process.env.REACT_APP_REALTIME_FILE_SIZE_LIMIT,
  taxFileSizeLimit: process.env.REACT_APP_TAX_FILE_SIZE_LIMIT,
  htFileSizeLimit: process.env.REACT_APP_HT_FILE_SIZE_LIMIT,
  vendorBulkOnboardingFileSizeLimit: process.env.REACT_APP_VENDOR_BULK_ONBOARDING_FILE_SIZE_LIMIT || 10000000,
  avatarFileSizeLimit: process.env.REACT_APP_AVATAR_FILE_SIZE_LIMIT || 5000000,
  htTotalFilesSize: process.env.REACT_APP_HT_TOTAL_FILES_SIZE,
  hubspotPortalID: process.env.REACT_APP_HUBSPOT_PORTAL_ID,

  // polling intervals
  taskPollInterval: process.env.REACT_APP_TASK_POLL_INTERVAL || 1500,
  notificationPollInterval: Number(process.env.REACT_APP_NOTIFICATION_POLL_INTERVAL) || 10000,

  // pusher
  pusherAppKey: process.env.REACT_APP_PUSHER_APP_KEY,
  pusherAppCluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,

  enabledJobRouting: process.env.REACT_APP_ENABLED_JOB_ROUTING_FEATURE || false,
  enabledVendorIndustry: process.env.REACT_APP_ENABLED_VENDOR_INDUSTRY_FEATURE || false,
  updatePermissionsOnLaunchFlex: process.env.REACT_APP_UPDATE_PERMISSIONS_ON_LAUNCH_FLEX || false,


  // GCP
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,

  // Feature Flags
  featureFlags: {
    opiCode: process.env.REACT_APP_FEATURE_FLAG_OPI_CODE === "true",
    vendorSettingsDesignPattern:
      process.env.REACT_APP_FEATURE_FLAG_VENDOR_SETTINGS_DESIGN_PATTERN ===
      "true",
    pmBillingAndReports:
      process.env.REACT_APP_FEATURE_FLAG_PM_BILLING_AND_REPORTS === "true",
    enabledVendorIndustry:
      process.env.REACT_APP_ENABLED_VENDOR_INDUSTRY_FEATURE || false,
    updatePermissionsOnLaunchFlex:
      process.env.REACT_APP_UPDATE_PERMISSIONS_ON_LAUNCH_FLEX || false,
    enabledVendorBulkOnboarding:
      process.env.REACT_APP_ENABLED_VENDOR_BULK_ONBOARDING || false,
  },
};

export default environmentVars;

export const isBuyerOrVendorApp = [BUYER_APP, VENDOR_APP].includes(
  environmentVars.appName,
);
export const isBuyerApp = environmentVars.appName === BUYER_APP;
export const isVendorApp = environmentVars.appName === VENDOR_APP;
export const isPMApp = environmentVars.appName === PM_APP;
