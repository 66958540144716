import * as FullStory from "@fullstory/browser";
import { COMPOSITION_VIDEO_STATUSES } from "../constants";

export {
  renderFullName,
  renderJobStatus,
  renderJobLanguagePair,
  renderDate,
  renderTime,
  renderDateAndTime,
  renderDuration,
  renderRate,
} from "./render";

/**
 * Converts bytes to human-readable format
 * @param size integer
 * @returns {string}
 */
export function convertBytes(size) {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let l = 0,
    n = parseInt(size, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
}

export const extractEmailDomain = (email) => {
  if (typeof email === "string") return email.split("@")[1];
  return null;
};

export const prepareCustomState = (loginType, key, value) => {
  let customState = { loginType };
  if (key && value) {
    customState = { ...customState, [key]: value };
  }
  return JSON.stringify(customState);
};

export const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

// todo: use common environment vars after LGO-848
export const initializeFullStory = () => {
  if (
    process.env.REACT_APP_FULLSTORY_ORG_ID !== "" &&
    typeof process.env.REACT_APP_FULLSTORY_ORG_ID !== "undefined"
  ) {
    FullStory.init({
      orgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
      namespace: process.env.REACT_APP_FULLSTORY_NAMESPACE,
      devMode: process.env.REACT_APP_ENV !== "production",
    });
  }
};

// Used for debugging local storage
export const proxyLocalStorage = () => {
  Object.defineProperty(window, "localStorage", {
    configurable: true,
    enumerable: true,
    value: new Proxy(localStorage, {
      set: function (ls, prop, value) {
        console.log(`direct assignment: ${prop} = ${value}`);
        ls[prop] = value;
        return true;
      },
      get: function (ls, prop) {
        // The only property access we care about is setItem. We pass
        // anything else back without complaint. But using the proxy
        // fouls 'this', setting it to this {set: fn(), get: fn()}
        // object.
        if (prop !== "setItem") {
          if (typeof ls[prop] === "function") {
            return ls[prop].bind(ls);
          } else {
            return ls[prop];
          }
        }
        // If you don't care about the key and value set, you can
        // drop a debugger statement here and just
        // "return ls[prop].bind(ls);"
        // Otherwise, return a custom function that does the logging
        // before calling setItem:
        return (...args) => {
          console.log(`setItem(${args.join()}) called`);
          debugger;
          ls.setItem.apply(ls, args);
        };
      },
    }),
  });
};

export const mapStatusIcon = (type) => {
  if (!type) {
    return "info";
  }
  switch (type.toLowerCase()) {
    case "complete":
    case "completed":
    case "active":
    case "closed":
    case "approved":
      return "success";
    case "overdue":
    case "unassigned":
    case "cancelled":
    case "declined":
      return "danger";
    case "in progress":
      return "primary";
    case "created":
    case "assigned":
    default:
      return "info";
  }
};

export const combineComponents = (...components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) =>
      ({ children }) => (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      ),
    ({ children }) => <>{children}</>,
  );
};

/**
 * Returns the boolean flag by filtering composition video status slugs
 *
 * @param {string} compositionStatus - The input string.
 */
export const isValidCompositionStatus = (compositionStatus) =>
  Object.values(COMPOSITION_VIDEO_STATUSES).includes(compositionStatus);

/**
 * Returns the first and last characters of a string in uppercase.
 *
 * @param {string} name - The input string.
 */
export const getFirstLastCharacters = (name) => {
  if (!name) return "NA";
  const nameParts = name.split(" ");
  // Get the first character of the first part
  const firstPartInitial = nameParts[0].charAt(0).toUpperCase();
  // Get the first character of the last part
  const lastPartInitial = nameParts[nameParts.length - 1]
    .charAt(0)
    .toUpperCase();
  return firstPartInitial + lastPartInitial;
};

/**
 * Compares two objects to check if they have the same keys and values.
 *
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} Returns true if the objects have the same keys and values, false otherwise.
 */
export const compareObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => obj1[key] === obj2[key]);
};

/**
 * Generate a display string for a list of names.
 * 
 * @param {string[]} names - Array of names to be displayed.
 * @param {number} [maxDisplay=2] - Maximum number of names to display before appending "and others".
 * @returns {string} - A formatted string of names.
 */

export const displayNames = (names, maxDisplay = 2) => {
  const nameCount = names.length;
  if (nameCount === 0) return "";

  if (nameCount <= maxDisplay) {
    return names.join(" and ");
  }

  return `${names.slice(0, maxDisplay).join(", ")} and others`;
};
