import { useField, useFormikContext } from "formik";

import { FormControl } from "@lango/common/features";
import {
  useFetchJobTypes,
  useJobTypeLanguages,
  useFetchAllIndustries,
} from "@lango/common/hooks";
import { ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY } from "@lango/common/constants";

// default job type category for job routings
const types = [ON_DEMAND_INTERPRETATION_JOB_TYPE_CATEGORY];

export const JobTypeLanguagePair = ({
  isMulti = false,
  crowd = true,
  enableGeneralRouting = true,
  ...props
}) => {
  const [{ value: jobType }] = useField("jobType");
  const { isSubmitting, setFieldValue } = useFormikContext();
  const { allJobTypes, jobTypesloading } = useFetchJobTypes(types);
  const { jobTypeLanguages, loading } = useJobTypeLanguages(jobType);
  const { loading: industryLoading, allIndustries } = useFetchAllIndustries();

  const handleChangeJobType = () => {
    setFieldValue("sourceLanguage", null);
    setFieldValue("targetLanguage", null);
  };

  return (
    <>
      <FormControl
        control="select"
        name="jobType"
        label="Job Type"
        placeholder="Job Type"
        isDisabled={isSubmitting}
        options={allJobTypes}
        onChange={handleChangeJobType}
        isLoading={jobTypesloading}
        whiteBackground
      />
      {jobType && (
        <>
          <FormControl
            control="select"
            label="Source Language"
            name="sourceLanguage"
            placeholder="Source Language"
            isDisabled={isSubmitting || loading}
            options={jobTypeLanguages}
            isLoading={loading}
            whiteBackground
          />
          <FormControl
            control="select"
            name="targetLanguage"
            label="Target Language"
            placeholder="Target Language"
            isDisabled={isSubmitting || loading}
            options={jobTypeLanguages}
            isLoading={loading}
            isMulti={isMulti}
            whiteBackground
            {...props}
          />
          <FormControl
            control="select"
            label="Industry (optional)"
            name="industry"
            placeholder="Select Industry"
            options={allIndustries}
            isDisabled={isSubmitting || industryLoading}
            isLoading={industryLoading}
            whiteBackground
            isClearable={true}
          />
          {enableGeneralRouting && (
            <FormControl
              control="toggle"
              label="Enable General Routing"
              name="enableGeneralRouting"
              disabled={isSubmitting}
              whiteBackground
            />
          )}
          {crowd && (
            <FormControl
              control="toggle"
              label="Use Lango Crowd"
              name="useLangoCrowd"
              disabled={isSubmitting}
              whiteBackground
            />
          )}
        </>
      )}
    </>
  );
};
