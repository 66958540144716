/**
 * This file contains the implementation of the second step of the onboarding process.
 * It includes two components: AgencyStep2 and IndividualStep2.
 * The Step2 component determines which component to render based on the vendor type.
 * If the vendor type is "Individual", it renders the IndividualStep2 component.
 * If the vendor type is "Agency", it renders the AgencyStep2 component.
 */

import { Form, Formik } from "formik";

import BasePageHeading from "@lango/common/features/header/components/BasePageHeading";
import { vendorContactInitialValues } from "@lango/common/initialValues";
import {
  useFetchVendorOnboardingStatus,
  useVendorOnboardingStep2,
  useVendorOnboardSteps,
} from "@lango/common/hooks";
import { validateVendorContact } from "@lango/common/validations";
import { useAcceptVendorInviteCtx } from "@lango/common/context";
import { ServiceLines } from "@lango/common/features/serviceLines/ServiceLines";
import { Action } from "@lango/common/features/onboarding";

import Fields from "./fields";
import Step1, { usePrepareUser } from "../step1";
import InfoBars from "../progressBar";

/**
 * Renders the Agency onboarding step.
 * It uses Formik for form handling and validation.
 * It displays a page heading and the Fields component.
 *
 * @returns {JSX.Element} The rendered AgencyStep2 component.
 */
function AgencyStep2() {
  const user = usePrepareUser();
  const { handleOnboardingStep2 } = useVendorOnboardSteps();
  const { vendorProfile: data } = useVendorOnboardingStep2();

  const initialValues = vendorContactInitialValues({
    city: data?.city,
    country: data?.country,
    address1: data?.address1,
    address2: data?.address2,
    state: data?.state,
    postalCode: data?.postalCode,
    phone: data?.phone,
    faxNumber: data?.faxNumber || "",
    ...user,
  });

  const vendorContactData = vendorContactInitialValues({
    city: data?.city,
    country: data?.country,
    address1: data?.address1,
    address2: data?.address2,
    state: data?.state,
    postalCode: data?.postalCode,
    phone: data?.phone,
    faxNumber: data?.faxNumber,
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: user?.email,
  });

  const { status } = useFetchVendorOnboardingStatus();

  const formInitialValues =
    status?.currentStep === "step2" ? vendorContactData : initialValues;

  const { colorOne, colorTwo } = secondStepColours(status?.currentStep);
  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={handleOnboardingStep2}
      validationSchema={validateVendorContact}
      enableReinitialize
    >
      <Form>
        <BasePageHeading
          heading="Agency onboarding"
          description="Step 2 of 3"
        />
        <InfoBars
          status={status?.currentStep}
          step2="Billing Contact"
          step3="Users"
          colorOne={colorOne}
          colorTwo={colorTwo}
        />
        <Fields fileName={data?.fileName} />
        <Action />
      </Form>
    </Formik>
  );
}

/**
 * Renders the Linguist onboarding step.
 * It displays a page heading and the ServiceLines component.
 *
 * @returns {JSX.Element} The rendered IndividualStep2 component.
 */
function IndividualStep2() {
  const { handleIndividualOnboardingStep2 } = useVendorOnboardSteps();
  const { onboardingData } = useAcceptVendorInviteCtx();
  const vendorProfileID = onboardingData?.vendorProfileID;

  const { status } = useFetchVendorOnboardingStatus();
  const { colorOne, colorTwo } = secondStepColours(status?.currentStep);

  return (
    <>
      <BasePageHeading
        heading="Linguist onboarding"
        description="Step 2 of 3"
      />
      <InfoBars
        status={status?.currentStep}
        step2={"Service Lines"}
        step3={"Language Pairs"}
        colorOne={colorOne}
        colorTwo={colorTwo}
      />
      <ServiceLines
        canUpdateOrg
        vendorProfileID={vendorProfileID}
        onBoardingLinguist={true}
        className="mt-8"
        buttonText="Save & continue"
        onSubmit={handleIndividualOnboardingStep2}
      />
    </>
  );
}

/**
 * Renders the second step of the onboarding process.
 * If the vendor type is "Individual", it renders the IndividualStep2 component.
 * If the vendor type is "Agency", it renders the AgencyStep2 component.
 *
 * @returns {JSX.Element} The rendered second step component.
 */
export default function Step2() {
  const { isIndividual } = useAcceptVendorInviteCtx();
  return isIndividual() ? <IndividualStep2 /> : <AgencyStep2 />;
}

function secondStepColours(currentStep) {
  return {
    colorOne: "black",
    colorTwo:
      currentStep === "step1" || currentStep === "step2" ? "white" : "black",
  };
}
