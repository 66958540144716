import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import classNames from "@lango/common/classnames";
import { mergeFilters } from "@lango/common/helpers/filter";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import _debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { CustomizeColumns } from "./CustomizeColumns";
import { FilterButton } from "./FilterButton";
import Pagination from "./Pagination";
import { QuickFilters } from "./QuickFilters";
import { useTableRouting } from "./tableRouting";

const AUTO_CONTROL_PAGE_COUNT = -1;

/**
 * Renders Table Component
 *
 * Renders a table component with various features like pagination, sorting, filtering, and more.
 *
 * @param {Object} props - Component props
 * @param {Array} props.columns - Columns configuration for the table
 * @param {Array} props.data - Data to be displayed in the table
 * @param {number} [props.totalRecords] - Total number of records/rows in the table
 * @param {string} [props.heading] - Heading for the table
 * @param {Function} [props.fetchData=() => {}] - Function to fetch data for the table
 * @param {Function} [props.onRowClick] - Handler function for row click events
 * @param {boolean} [props.enablePagination] - Indicates if pagination is enabled
 * @param {number} [props.defaultPageSize=25] - Default page size for pagination
 * @param {string} [props.dataTrigger] - Trigger for data updates
 * @param {boolean} [props.skipPageReset] - Indicates whether to skip page reset
 * @param {Array} [props.defaultPageSizes] - Default page sizes for pagination
 * @param {Function} [props.updatePageCount] - Function to update the page count
 * @param {boolean} [props.showColumns] - Indicates whether to show column options
 * @param {number} [props.pageCount] - Page count for pagination
 * @param {React.Component} [props.SearchModal] - The component to use for Search Modal. If not provided, search button will not display.
 * @param {React.Component} [props.HeaderComponent] - Optional header component, that will be passed filter information.
 * @param {Array} [props.hideFilters] - Disable filtering.
 * @param {Array} [props.quickFilters] - Set of filters & labels to use for quick filtering.
 * @param {boolean} [props.expandQuickFilters] - Indicates whether display quick filters as tabs.
 * @param {React.Component} [props.ExportComponent] - The component to use for Export button.
 * @param {string} [props.searchButtonClasses] - CSS classes for the search button
 * @param {Array} [props.defaultSortBy=[]] - Default sorting configuration
 * @param {Function} [props.onSelectedRowsChange] - Handler function for selected row changes
 * @param {boolean} [props.stickyHeaders] - Indicates whether to make table headers sticky
 * @param {Object} [props.initialFilters] - Initial filters for the table
 * @param {string} [props.settingContainerClasses] - CSS classes for the setting container
 * @param {React.Component} [props.exportComponent] - Component for exporting data
 * @param {Object} [props.exportProps] - Props for the export component
 * @param {Function} [props.rowSelectionHandler] - Handler function for row selection
 * @param {string} [props.distinguisher] - When using multiple tables on a page, this is used to distinguish between them. Ideally a single character like "a" or "b".
 */
const Table = ({
  columns,
  data,
  totalRecords = 0,
  heading,
  fetchData = () => {},
  onRowClick,
  enablePagination,
  defaultPageSize = 25,
  dataTrigger,
  skipPageReset,
  defaultPageSizes,
  updatePageCount,
  showColumns,
  pageCount: controlledPageCount,
  SearchModal,
  HeaderComponent,
  hideFilters = [],
  quickFilters,
  expandQuickFilters,
  defaultSortBy = [],
  onSelectedRowsChange,
  stickyHeaders,
  initialFilters,
  settingContainerClasses,
  ExportComponent,
  exportProps,
  rowSelectionHandler = null,
  distinguisher = "",
}) => {
  const [showSearchModal, setShowSearchModal] = useState(false);

  const {
    sorting,
    setSorting,
    pagination,
    setPagination,
    filters,
    setFilters,
    loading: loadingTableRouting,
  } = useTableRouting({
    initialFilters,
    initialSorts: defaultSortBy,
    initialPagination: { pageSize: defaultPageSize, pageIndex: 0 },
    distinguisher,
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: rowSelectionHandler,
    manualPagination: true,
    manualSorting: true,
    pageCount: controlledPageCount || AUTO_CONTROL_PAGE_COUNT,
    autoResetAll: false,
    autoResetPageIndex: !skipPageReset,
    getRowId: (row, index) => (row.id !== undefined ? row.id : index),
  });

  useEffect(() => {
    const selectedIds = Object.keys(table.getState().rowSelection);
    onSelectedRowsChange && onSelectedRowsChange(selectedIds);
  }, [onSelectedRowsChange, table.getState().rowSelection]);

  // Debounce our onFetchData call for 100ms
  const onFetchDataDebounced = _debounce(fetchData, 100);

  useEffect(() => {
    if (fetchData && !loadingTableRouting) {
      const tableState = table.getState();
      onFetchDataDebounced({
        pageIndex: tableState.pagination.pageIndex,
        pageSize: tableState.pagination.pageSize,
        sortBy: tableState.sorting,
        filters,
        distinguisher,
      });
    }
  }, [
    dataTrigger,
    fetchData,
    table.getState().pagination.pageSize,
    table.getState().sorting,
    table.getState().pagination.pageIndex,
    JSON.stringify(filters),
    loadingTableRouting,
    distinguisher,
  ]);

  const onPageChanged = ({ page, limit }) => {
    table.setPagination({ pageIndex: page - 1, pageSize: limit });
    if (updatePageCount) {
      updatePageCount(limit);
    }
  };

  const paginationProps = {
    totalRecords: totalRecords,
    pageLimit: table.getState().pagination.pageSize,
    pageNeighbors: 1,
    pageLimits: defaultPageSizes || [5, 10, 25],
    currentPage: table.getState().pagination.pageIndex + 1,
    onPageChanged: onPageChanged,
    heading: heading,
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = table.getAllColumns();
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    table.setColumnOrder(items.map((d) => d.id));
  };

  const tableContainerClasses = classNames(
    "border-b border-gray-200 shadow sm:rounded-lg",
    {
      "overflow-auto max-h-96": stickyHeaders,
      "overflow-hidden": !stickyHeaders,
    },
  );

  const headClasses = classNames(
    "text-left text-xs text-xs font-bold uppercase",
    {
      "bg-gray-100 sticky top-0": stickyHeaders,
      "bg-gray-300/20": !stickyHeaders,
    },
  );

  return (
    <div className="relative mt-0">
      {SearchModal && (
        <SearchModal
          setShowModal={setShowSearchModal}
          setFilters={(prevFilters) => {
            const updatedFilters =
              prevFilters && initialFilters
                ? mergeFilters(prevFilters, initialFilters)
                : prevFilters;
            setFilters(updatedFilters);
          }}
          showSearchModal={showSearchModal}
          currentFilters={filters}
          hideFilters={hideFilters}
        />
      )}
      <div className="flex w-full flex-col">
        <div
          className={classNames(
            "flex flex-1 items-center justify-space-between",
            {
              // If quickFilters are displayed as tabs, don't display this bar inline.
              "absolute z-10 -top-10 right-0 md:-top-16": !expandQuickFilters,
              "mb-6": expandQuickFilters,
            },
            settingContainerClasses,
          )}
        >
          {quickFilters && (
            <QuickFilters
              quickFilters={quickFilters}
              setFilters={setFilters}
              expandQuickFilters={expandQuickFilters}
            />
          )}
          {SearchModal && (
            <FilterButton
              filters={filters}
              onClick={() => setShowSearchModal(true)}
            />
          )}
          <span className="inline">
            {showColumns && (
              <CustomizeColumns
                columns={table.getAllColumns()}
                handleOnDragEnd={handleOnDragEnd}
              />
            )}
          </span>
          {ExportComponent && exportProps && totalRecords > 0 && (
            <ExportComponent filters={filters} {...exportProps} />
          )}
        </div>
        {HeaderComponent && <HeaderComponent filters={filters} />}
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
            <div className={tableContainerClasses}>
              <table className="min-w-full divide-y">
                <thead className={headClasses}>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          scope="col"
                          className={classNames(
                            "px-4 py-5 tracking-wider",
                            {
                              "cursor-pointer select-none":
                                header.column.getCanSort(),
                            },
                            header.classNames,
                          )}
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                          {{
                            asc: <ChevronUpIcon className="inline w-4" />,
                            desc: <ChevronDownIcon className="inline w-4" />,
                          }[header.column.getIsSorted()] ?? null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y divide-gray-100 bg-white">
                  {table.getRowModel().rows.map((row) => {
                    const rowClass = onRowClick
                      ? "cursor-pointer hover:bg-gray-100"
                      : null;
                    return (
                      <tr
                        className={rowClass}
                        onClick={() => {
                          if (onRowClick) {
                            onRowClick(row.original);
                          }
                        }}
                        key={row.id}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            className={classNames(
                              "whitespace-nowrap px-4 py-4",
                              cell.column.classNames,
                            )}
                            key={cell.id}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {enablePagination && <Pagination {...paginationProps} />}
      </div>
    </div>
  );
};

export default Table;
