import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

import { useFetchVendorProfile } from "@lango/common/hooks";
import { WithLoader } from "@lango/common/components";

export const VendorProfileContext = createContext({
  loading: false,
  error: null,
  vendorProfile: null,
});

/* *
 * Provider component for managing vendor profiles.
 * It provides a context for accessing the vendorProfiles.
 * It also renders the child components passed to it.
 */

export const VendorProfileProvider = ({ children }) => {
  const { vendorProfileID } = useParams();
  const { loading, error, vendorProfile } =
    useFetchVendorProfile(vendorProfileID);

  return (
    <VendorProfileContext.Provider value={{ loading, error, vendorProfile }}>
      <WithLoader loading={loading} error={error}>
        {children}
      </WithLoader>
    </VendorProfileContext.Provider>
  );
};

/**
 * Custom hook for accessing the VendorProfileContext.
 */

export function useVendorProfile() {
  const context = useContext(VendorProfileContext);
  if (!context) {
    throw new Error(
      "useVendorProfile must be used within a VendorProfileProvider",
    );
  }
  return context;
}
