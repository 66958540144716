import { INPUT_DATE_FORMAT } from "@lango/common/constants";
import moment from "moment-timezone";
import { renderFullName } from "@lango/common/helpers";

export const loginFormInitialValues = {
  username: "",
  password: "",
  localLogin: false,
  ssoProviderName: "",
  disableFields: false,
};

export const confirmPasswordInitialValues = (username = "") => {
  return {
    username,
    code: "",
    newPassword: "",
    repeatNewPassword: "",
  };
};

export const forgotPasswordInitialValues = { username: "" };

export const noPreference = { label: "No Preference", value: 0 };

/**
 * JobRoutingInitialValues Object
 *
 *  @returns {Object} the initial values for creating a job routing.
 */
export const jobRoutingInitialValues = {
  sourceLanguage: null,
  targetLanguage: [],
  jobType: null,
  useLangoCrowd: null,
  industry: null,
  enableGeneralRouting: false,
};

/**
 * routingLevelInitialValues Object
 *
 *  @returns {Object} the initial values for creating a new routing level.
 */
export const routingLevelInitialValues = {
  levelName: "",
  vendors: [],
};

/**
 * assignVendorsInitialValues Object
 *
 *  @returns {Object} the initial values for assigning new vendors to routing level.
 */
export const assignVendorsInitialValues = {
  vendors: [],
};

/**
 * mtUsageFilterInitialValues Object
 *
 *  @returns {Object} the initial values for client MT usage filter.
 */
export const mtUsageFilterInitialValues = {
  filter: {
    value: "LastSevenDays",
    label: "Last 7 Days",
  },
};

/**
 * pmDashboardInitialValues Object
 *
 *  @returns {Object} the initial values for PM app dashboard jobs & stats.
 */
export const pmDashboardInitialValues = {
  filter: {
    value: "Today",
    label: "Today",
  },
};

/**
 * Prepares organization labels
 *
 * @param {Array<Object>} labels - The array of label objects.
 * @returns {Array<string>} The array of label strings.
 */
const prepareOrgLabels = (labels) => labels?.map((label) => label?.label);

/**
 * Prepares the estimated price by formatting it to two decimal places.
 *
 * @param {number} estimatedPrice - The estimated price.
 * @returns {string} - The formatted estimated price string.
 */
const prepareEstimatedPrice = (estimatedPrice) =>
  estimatedPrice ? estimatedPrice.toFixed(2) : "0.00";

/**
 * prepareHTInitialFormValues Function
 *
 * This function generates initial values for HT job form based on the provided data.
 *
 *  @returns {Object} the initial values for HT job form.
 */
export const prepareHTInitialFormValues = (values = {}) => {
  const {
    internalNotes = "",
    customerNotes = "",
    jobType = {},
    labels = [],
    organization = {},
    createdByUser,
    languageFrom,
    languageTo,
    estimatedPrice,
    requestedCompletionDate: requestedDate,
    jobFiles = [],
    jobStatus,
    wordCount = 0,
  } = values;

  return {
    internalNotes,
    customerNotes,
    jobtype: jobType.name || "",
    orgLabels: prepareOrgLabels(labels),
    company: organization.name || "",
    requester: renderFullName(createdByUser),
    sourceLanguage: languageFrom,
    targetLanguage: languageTo,
    estimatedPrice: prepareEstimatedPrice(estimatedPrice),
    requestedDate: requestedDate
      ? moment(requestedDate).format(INPUT_DATE_FORMAT)
      : "",
    jobFiles,
    jobStatus,
    wordCount,
  };
};

/**
 * prepareOnDemandInitialFormValues Function
 *
 * This function generates initial values for on-demand job form based on the provided data.
 *
 *  @returns {Object} the initial values for on-demand job form.
 */
export const prepareOnDemandInitialFormValues = (values = {}) => {
  const {
    internalNotes = "",
    customerNotes = "",
    labels = [],
    jobType = {},
    organization,
    createdByUser,
    languageFrom: sourceLanguage,
    languageTo: targetLanguage,
    estimatedPrice,
    requestedCompletionDate: requestedDate,
    tasks = [],
    jobStatus,
  } = values;
  const task = tasks?.[0];

  return {
    internalNotes,
    customerNotes,
    jobtype: jobType.name || "",
    orgLabels: prepareOrgLabels(labels),
    company: organization?.name || "",
    requester: renderFullName(createdByUser),
    sourceLanguage,
    targetLanguage,
    estimatedPrice: prepareEstimatedPrice(estimatedPrice),
    organizationID: organization?.id,
    requestedDate: requestedDate
      ? moment(requestedDate).format(INPUT_DATE_FORMAT)
      : "",
    gender: task?.gender?.label || "",
    assignedUser: renderFullName(task?.assignedUser),
    jobStatus,
    startTime: prepareTimeFieldValue(task?.startTime),
    endTime: prepareTimeFieldValue(task?.endTime),
    vendorName: task?.vendorOrg?.name || "",
    servicedByCrowd: task?.servicedByCrowd,
  };
};

function prepareTimeFieldValue(time) {
  return time ? moment(time).format("HH:mm") : "";
}

/**
 * prepareHTJobTaskInitialValues Function
 *
 * This function generates initial values for HT job task modal form based on the provided data.
 *
 *  @returns {Object} the initial values for HT job task modal.
 */
export const prepareHTJobTaskInitialValues = (row = {}) => {
  const { id, taskType, rate, targetDate, vendor } = row;
  return {
    id,
    taskType,
    rate: rate || "0.00",
    vendor,
    targetDate: targetDate ? moment(targetDate).format(INPUT_DATE_FORMAT) : "",
  };
};

export const vendorLanguageInitialValues = {
  sourceLanguage: null,
  targetLanguage: null,
  jobType: null,
  industry: null,
};

export const vendorLanguageUpdateInitialValues = (row = {}) => {
  const { languageFrom, languageTo, industry, id, jobType } = row;
  return {
    id,
    sourceLanguage: languageFrom,
    targetLanguage: languageTo,
    industry,
    jobType,
  };
};

/**
 * vendorInvitationInitialValues Function
 *
 * This function generates the initial values for a vendor invitation form.
 * It uses the provided values and sets default values for certain fields.
 *
 * @param {Object} values - An object containing initial values for the form fields.
 * @returns {Object} An object with default and provided initial values for the form fields.
 */
export const vendorInvitationInitialValues = (values = {}) => {
  const { email, firstName, lastName } = values;
  return {
    ...values,
    email,
    firstName,
    lastName,
    username: email,
    password: "",
    confirmPassword: "",
    localLogin: true,
    disableFields: false,
  };
};

/**
 * vendorContactInitialValues Object
 *
 * @returns {Object} The initial values for adding vendor contact.
 */
export const vendorContactInitialValues = ({
  firstName = "",
  lastName = "",
  email = "",
  phone = "",
  faxNumber = "",
  country = "",
  address1 = "",
  address2 = "",
  city = "",
  postalCode = "",
  taxFormID = null,
  state = "",
}) => ({
  firstName,
  lastName,
  email,
  phone,
  faxNumber,
  country,
  address1,
  address2,
  city,
  postalCode,
  taxFormID,
  state,
});

export const vendorOnboardStep1InitialValues = ({
  name = "",
  firstName = "",
  lastName = "",
  email = "",
  address1 = "",
  address2 = "",
  city = "",
  country = "",
  postalCode = "",
  state = "",
  industries = [],
  title = "",
  phoneNumber = "",
}) => {
  return {
    name,
    firstName,
    lastName,
    email,
    address1,
    address2,
    city,
    postalCode,
    state,
    country,
    industries,
    title,
    phoneNumber,
  };
};

/**
 * clientContactInitialValues Object
 *
 * @returns {Object} The initial values for adding vendor contact.
 */
export const clientContactInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  faxNumber: "",
  taxFormID: null,
  city: "",
  country: "",
  address1: "",
  address2: "",
  state: "",
  postalCode: "",
};

export const clientOnboardStep1InitialValues = (name = "") => ({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  website: "",
  title: "",
  faxNumber: "",
  name,
  industries: [],
});

export const clientOnboardStep3InitialValues = {
  serviceLocations: [
    { locationName: "", address1: "", address2: "", city: "", zipCode: "" },
  ],
};

export const individualOnboardStep2InitialValues = ({
  firstName = "",
  lastName = "",
  email = "",
  address1 = "",
  address2 = "",
  city = "",
  postalCode = "",
  country = "",
  state = "",
  phone = "",
  gender = null,
}) => ({
  firstName,
  lastName,
  email,
  gender,
  phone,
  address1,
  address2,
  city,
  postalCode,
  country,
  state,
});

export const createVendorInitialValues = {
  name: "",
  firstName: "",
  lastName: "",
  vendorType: null,
};
