import React from "react";

import Tabs from "./Tabs";
import { primaryTabStyles } from "./styles";

// You can use this component for the legacy pattern of panels, etc.
// Or upgrade to using the new Tabs component directly.
const Tab = ({
  children,
  header = null,
  styles = primaryTabStyles,
  tabIcon = null,
}) => {
  const tabs = React.Children.map(children, (child, index) => {
    if (!child) return;
    return { label: child.props.name, element: <>{child.props.children}</> };
  });

  return <Tabs styles={styles} tabs={tabs} header={header} tabIcon={tabIcon} />;
};

const TabPanel = (props) => <div {...props} />;
Tab.Panel = TabPanel;

export default Tab;
