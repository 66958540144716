import { PopoverPanel, Transition } from "@headlessui/react";

import {
  BasePageHeading,
  Button,
  DropdownTriangle,
  Flex,
} from "@lango/common/features";
import { OrganizationNotification } from "./notification";

/**
 * NotificationPanel Component
 *
 * A panel that displays a list of notifications for the organization. It includes a heading,
 * a "Mark all as read" button, and a "View all notifications" button. The notifications
 * are displayed in a scrollable area, and each notification can be clicked to trigger
 * a callback function. The panel also handles transitions for opening and closing.
 *
 * @param {Object} props - The props for the NotificationPanel component.
 * @param {Array} props.notifications - The array of notification objects to be displayed.
 * @param {function} [props.handleClick] - The function to be called when a notification is clicked.
 * @param {function} [props.handleAllRead] - The function to be called when the "Mark all as read" button is clicked.
 * @param {function} [props.handleViewAll] - The function to be called when the "View all notifications" button is clicked.
 * @returns {React.ReactNode} The rendered NotificationPanel component.
 */
export const NotificationPanel = ({
  notifications,
  handleClick,
  handleViewAll,
  handleAllRead,
}) => {
  const hasUnreadNotifications = notifications.some((n) => !n.isRead);
  return (
    <PanelTransition>
      <PopoverPanel className="absolute left-6 w-screen max-w-xs -translate-x-72 transform sm:px-0 lg:max-w-xl">
        {({ close }) => (
          <>
            <DropdownTriangle positionClasses="justify-center pr-4" />
            <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5">
              <div className="relative flex flex-col bg-white h-[536px]">
                <div className="flex-1 overflow-y-auto">
                  <Flex extraClasses="p-6" justify="between" align="center">
                    {/* Panel header with the title and "Mark all as read" button */}
                    <BasePageHeading heading="Notifications" />
                    <Button
                      text="Mark all as read"
                      textButton
                      onClick={() => {
                        handleAllRead?.(notifications?.[0]?.organizationID);
                        close();
                      }}
                      disabled={!hasUnreadNotifications}
                    />
                  </Flex>
                  {/* Render each notification in the list */}
                  {notifications.map((notification, key) => (
                    <OrganizationNotification
                      key={key}
                      notification={notification}
                      onClick={() => {
                        handleClick?.(notification?.id, notification?.sentByID);
                        close();
                      }}
                    />
                  ))}
                </div>
                {/* Footer with "View all notifications" button */}
                <Flex
                  justify="center"
                  extraClasses="py-6 border-t border-gray-200 bg-white text-base font-medium underline"
                >
                  <Button
                    text="View all notifications"
                    textButton
                    onClick={() => {
                      handleViewAll?.();
                      close();
                    }}
                  />
                </Flex>
              </div>
            </div>
          </>
        )}
      </PopoverPanel>
    </PanelTransition>
  );
};

/**
 * PanelTransition Component
 *
 * Provides transition effects for the NotificationPanel component. This component
 * wraps its children with transitions that animate the appearance and disappearance
 * of the notification panel. It enhances the user experience by adding smooth
 * transitions when the panel is shown or hidden.
 *
 * @param {Object} props - The props for the PanelTransition component.
 * @param {React.ReactNode} props.children - The child components to which the transition effects will be applied.
 * @returns {React.ReactNode} The rendered transition component with its children.
 */
const PanelTransition = ({ children }) => {
  return (
    <Transition
      enter="transition ease-out duration-75"
      enterFrom="opacity-0 -translate-y-2"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-50"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-2"
    >
      {children}
    </Transition>
  );
};
