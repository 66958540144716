import { Outlet } from "react-router-dom";

import { HubspotChat } from "@lango/common/components/HubspotChatWidget";
import ToastContainer from "@lango/common/features/alerts/components/toastContainer";
import AutoLogout from "@lango/common/features/auth/components/AutoLogout";
import Header from "@lango/common/features/header/components";
import HeaderLink from "@lango/common/features/header/components/HeaderLink";
import { VENDOR_ROUTES } from "@lango/common/routes";
import { UserPictureProvider, ModalRoot } from "@lango/common/context";
import { OrganizationNotifications } from "@lango/common/features";

import OnDemandRequest from "./containers/OnDemandRequest";

const AppContainer = () => {
  return (
    <div className="App">
      <UserPictureProvider>
        <div className="flex h-screen flex-col">
          <Header
            extraClasses="items-center justify-between"
            notification={<OrganizationNotifications extraClasses="relative z-10" />}
          >
            <HeaderLink to={VENDOR_ROUTES.REQUESTS} name="Requests" />
            <OnDemandRequest />
          </Header>
          <main className="grow py-4 px-4 sm:py-8 sm:px-6 lg:py-10 lg:px-8">
            <section className="mx-auto grid max-w-screen-2xl grid-cols-1">
              <ToastContainer />
              <AutoLogout />
              <Outlet />
              <HubspotChat />
              <ModalRoot />
            </section>
          </main>
        </div>
      </UserPictureProvider>
    </div>
  );
};

export default AppContainer;
