import { useQuery, useReactiveVar } from "@apollo/client";

import { activeOrgVar } from "../apollo/localState";
import { ALL_ORG_VENDORS } from "../queries";

/**
 * useAllOrgVendors Hook
 *
 * This hook fetches vendors based on the provided active company ID.
 * It utilizes useQuery hook from Apollo Client for data fetching.
 *
 * @hook useAllOrgVendors
 */
export const useAllOrgVendors = () => {
  const { id: organizationID } = useReactiveVar(activeOrgVar);

  const { loading, error, data } = useQuery(ALL_ORG_VENDORS, {
    variables: { organizationID, pageLimit: 500, offset: 0 },
  });

  return {
    loading,
    error,
    vendors: data?.allOrgVendors?.vendorProfiles?.map((profile) => profile.vendor),
  };
};
