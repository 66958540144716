import { FilterIcon } from "@heroicons/react/outline";
import { filterTypes } from "@lango/common/request";
import { IconButton } from "./IconButton";

export const FilterButton = ({ onClick, filters }) => {
  const filterCount =
    filters &&
    filters[filterTypes.common].length + filters[filterTypes.custom].length;
  return (
    <div className="relative">
      <IconButton Icon={FilterIcon} title="Filter" onClick={onClick} />
      {!!filterCount && (
        <span className="bg-black text-white absolute rounded-full w-4 h-4 -right-0 -top-0 flex items-center justify-center text-[0.6rem]">
          {filterCount}
        </span>
      )}
    </div>
  );
};
