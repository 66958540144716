import { gql } from "@apollo/client";

export const CAN_EDIT_PROJECT = gql`
  query CanEditProject($projectID: ID!, $organizationID: ID!) {
    canEditProject(organizationID: $organizationID, projectID: $projectID)
  }
`;

export const CAN_EDIT_JOB = gql`
  query CanEditJob($jobID: ID!, $organizationID: ID!) {
    canEditJob(organizationID: $organizationID, jobID: $jobID)
  }
`;

export const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings($input: UpdateUserSettingsRequest!) {
    updateUserSettings(input: $input)
  }
`;

export const UPLOAD_USER_PICTURE = gql`
  mutation uploadUserPicture($file: Upload!) {
    uploadUserPicture(file: $file)
  }
`;