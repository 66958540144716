import { Divider } from "@aws-amplify/ui-react";
import { IoIosArrowForward } from "react-icons/io";

import { Avatar, TimeAgo } from "@lango/common/components";
import { getFirstLastCharacters } from "@lango/common/helpers";
import cn from "@lango/common/classnames";

/**
 * OrganizationNotification Component
 *
 * Displays a single notification item within the notifications panel. This component
 * shows the notification's avatar, title, and description. The title and description
 * change style based on whether the notification has been read or not. It also includes
 * a right arrow icon to indicate that the item is clickable.
 *
 * @param {Object} props - The props for the OrganizationNotification component.
 * @param {Object} props.notification - The notification object containing details to display.
 * @param {string} props.notification.title - The title of the notification.
 * @param {string} props.notification.description - The description of the notification.
 * @param {string} props.notification.name - The name associated with the notification for the avatar.
 * @param {string} props.notification.createdAt - It is the time when the notification is created.
 * @param {any} props.notification.sentBy - It indicates who sent notification
 * @param {boolean} props.notification.isRead - Indicates if the notification has been read.
 * @param {any} [props.onClick] - The function to be called when the notification is clicked.
 * @returns {React.ReactNode} The rendered OrganizationNotification component.
 */
export const OrganizationNotification = ({ notification, onClick }) => {
  const { title, description, sentBy, isRead, createdAt } = notification;
  const readColor = isRead ? "text-gray-400" : "text-black";

  return (
    <div className="transition duration-150 ease-in-out hover:bg-gray-100">
      <Divider />
      <div onClick={!isRead ? onClick : null} className={cn(`flex py-5 px-6 w-full items-start ${!isRead ? `cursor-pointer`: "" }`)}>
        <div className="flex-shrink-0 flex items-start justify-center w-16">
          <Avatar
            placeholderInitials={getFirstLastCharacters(sentBy?.name)}
            extraClasses="w-14 h-14 text-xl font-bold border-gray-200 border"
          />
        </div>
        <div className="flex-grow mx-4 flex flex-col justify-center">
          <p className={`text-base font-bold ${readColor}`}>{title}</p>
          <p className={`text-sm font-normal mt-1 ${readColor}`}>{description}</p>
        </div>
        <div className="flex-shrink-0 w-28 flex flex-col items-end justify-between gap-4">
          <TimeAgo date={createdAt} extraClasses={readColor} />
          <IoIosArrowForward size="28px" className={readColor} />
        </div>
      </div>
    </div>
  );
};
