import { createContext, useContext, useState, useEffect } from "react";
import { useFetchUserPicture } from "@lango/common/hooks";

const UserPictureContext = createContext({
  userPicture: null,
  setUserPicture: (any) => {},
});

export const UserPictureProvider = ({ children }) => {
  const [userPicture, setUserPicture] = useState(null);
  const picture = useFetchUserPicture();

  useEffect(() => setUserPicture(picture), [picture]);

  return (
    <UserPictureContext.Provider value={{ userPicture, setUserPicture }}>
      {children}
    </UserPictureContext.Provider>
  );
};

export const useUserPictureContext = () => {
  const context = useContext(UserPictureContext);
  if (!context) {
    throw new Error(
      "useUserPictureContext must be used within a UserPictureProvider",
    );
  }
  return context;
};
