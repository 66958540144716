import { Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import React, { useState } from "react";

import classNames from "@lango/common/classnames";
import { Flex } from "@lango/common/features";

const CollapsibleSection = (props) => {
  const [visible, setVisible] = useState(props.visible !== false);
  const iconClasses = "w-6 mx-auto";
  const collapseClasses = classNames(
    "focus:outline-none inline w-8 h-8 rounded-full float-right",
    props.collapseBackground
  );

  const clickClasses = props.openText || props.closeText ? "float-right text-base" : collapseClasses;
  const handleClick = () => setVisible(!visible);

  const handleDelete = (e) => {
    if (props.deleteHandler) {
      props.deleteHandler(e);
    }
  };

  const renderDeleteButton = () => {
    if (props.deleteHandler) {
      let btnClass = classNames(collapseClasses, props.collapseBackground);
      return (
        <button type={"button"} className={btnClass} onClick={handleDelete}>
          <TrashIcon className={iconClasses} />
        </button>
      );
    }
  };

  const renderChevron = () => {
    if (visible) {
      return (
        <Flex onClick={handleClick} extraClasses="gap-2" align="center">
          {props.openText} <ChevronUpIcon className={iconClasses} />
        </Flex>
      );
    } else {
      return (
        <Flex onClick={handleClick} extraClasses="gap-2" align="center">
          {props.closeText} <ChevronDownIcon className={iconClasses} />
        </Flex>
      );
    }
  };

  return (
    <div className={props.classNames}>
      <div className={"h-10"}>
        <Flex justify="between">
          <Flex align="center" extraClasses="gap-1">
            {props.customHeader}
            <h3 className={props.textClasses}>{props.sectionTitle}</h3>
            <h3 className="text-sm">{props.secondaryTitle}</h3>
          </Flex>
          <button type="button" className={clickClasses}>
            {renderChevron()}
          </button>
        </Flex>
        {renderDeleteButton()}
      </div>
      <Transition
        show={visible}
        enter="transition-opacity duration-50"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-25"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>{props.children}</div>
      </Transition>
    </div>
  );
};

CollapsibleSection.defaultProps = {
  classNames: null,
  textClasses: "text-md font-bold",
  collapseBackground: "bg-white",
  deleteHandler: null,
};

export default CollapsibleSection;
