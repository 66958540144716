import FileDownload from "@lango/common/components/FileDownload";
import { toastError } from "@lango/common/features/alerts/functions/toast";
import { saveAs } from "file-saver";

const useDownloadProjectJobExportFile = (onClose) => {
  const { setupDownloadRequest } = FileDownload();

  const downloadProjectJobExportFile = async (orgID, projectID, fileName) => {
    const requestOptions = setupDownloadRequest({
      organizationId: orgID,
      projectId: parseInt(projectID),
      fileName: fileName,
    });
    if (!requestOptions) {
      toastError("download error");
      onClose && onClose();
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_URL}/download_project_jobs_export_file`,
        requestOptions,
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const blob = await response.blob();
      saveAs(blob, fileName);
      onClose && onClose();
    } catch (error) {
      toastError(error.message);
      onClose && onClose();
    }
  };

  return { downloadProjectJobExportFile };
};

export default useDownloadProjectJobExportFile;
