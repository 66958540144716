import { useReactiveVar } from "@apollo/client";
import { fetchAuthSession } from "aws-amplify/auth";
import { useCallback, useEffect, useState } from "react";

import { hasPictureVar } from "@lango/common/apollo";
import { COMMON_ROUTES } from "@lango/common/routes";

export const useFetchUserPicture = () => {
  const [imageObjectURL, setImageObjectURL] = useState(null);
  const hasPicture = useReactiveVar(hasPictureVar);

  const handleFetchUserPicture = useCallback(async () => {
    try {
      if (!hasPicture) return;
      const res = await handleFetchRequest({}, "POST", COMMON_ROUTES.DOWNLOAD_USER_PICTURE);
      const imageBlob = await res.blob();
      const objectURL = URL.createObjectURL(imageBlob);
      setImageObjectURL(objectURL);
    } catch (error) {
      console.error("error fetching user picture:", error);
    }
  }, [hasPicture]);

  useEffect(() => {
    handleFetchUserPicture();
  }, [handleFetchUserPicture]);

  return imageObjectURL;
};

const handleFetchRequest = async (params = {}, method = "GET", path = "") => {
  const { tokens } = await fetchAuthSession();
  const request = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: tokens ? `Bearer ${tokens?.idToken}` : "",
    },
    ...params,
  };

  return await fetch(`${process.env.REACT_APP_HOST_URL}${path}`, request);
};
